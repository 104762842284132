import { useRef, ReactNode } from "react";
import { Canvas } from "@react-three/fiber";

import { useSelector, useDispatch } from "react-redux";
import { RootState, setTrackerAction } from "../store";

import { FaceTracker, ZapparCamera } from "@zappar/zappar-react-three-fiber";

type CanvasWrapperProps = {
  children: ReactNode;
};

export default function CanvasWrapper(props: CanvasWrapperProps) {
  const visType = useSelector((state: RootState) => state.visualisationType);
  const editOpen = useSelector((state: RootState) => state.editOpen);
  const trackerGroup = useRef();
  const dispatch = useDispatch();

  return (
    <Canvas gl={{ preserveDrawingBuffer: true, alpha: false }} linear>
      {visType === "vto" && !editOpen ? (
        <>
          <ZapparCamera rearCameraMirrorMode="css" userFacing />
          <FaceTracker
            ref={trackerGroup}
            onVisible={() => {
              if (trackerGroup) {
                const trackerProxy: any = trackerGroup;
                dispatch(setTrackerAction("TRACKER", trackerProxy));
              }
            }}
          >
            {props.children}
          </FaceTracker>
        </>
      ) : (
        props.children
      )}
    </Canvas>
  );
}
