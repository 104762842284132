import React, { useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Group, Object3D, Quaternion, Vector3 } from "three";
import GUI from "lil-gui";

type ItemProps = {
  item: Group | undefined;
};

type Bone = {
  object: Object3D;
  initialPos: Vector3;
  parentPos: Vector3;
  depth: number;
};

const guiVars = {
  onZ: true,
  physicsStrength: 1,
};

export default function Physics({ item }: ItemProps) {
  const [bones, setBones] = useState<Bone[]>();
  const [prevPosition, setPrevPosition] = useState(0);
  const [prevRotation, setPrevRotation] = useState(0);
  const [newPos, setNewPos] = useState(0);
  const [newRot, setNewRot] = useState(0);
  const [zAxis, setZAxis] = useState(guiVars.onZ);
  const [physicsStrength, setPhysicsStrength] = useState(
    guiVars.physicsStrength
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("physicsDebug")) {
      const gui = new GUI();
      gui
        .add(guiVars, "onZ")
        .name("Physics")
        .onChange((newValue: boolean) => {
          setZAxis(newValue);
        });
      gui
        .add(guiVars, "physicsStrength")
        .name("Physics Strength")
        .min(1)
        .max(3)
        .onChange((newValue: number) => {
          setPhysicsStrength(newValue);
        });
    }
  }, []);

  useEffect(() => {
    const newBones: Bone[] = [];

    item?.traverse((node) => {
      if (node.type === "Bone") {
        let parentBone = node;
        let depth = 0;
        let root = false;
        while (!root) {
          if (parentBone?.parent?.parent?.type === "Bone") {
            if (parentBone?.parent) {
              depth++;
              parentBone = parentBone?.parent;
            }
          } else {
            root = true;
          }
        }

        if (depth > 0) {
          newBones.push({
            object: node,
            depth: depth,
            initialPos: node.position.clone(),
            parentPos: parentBone.position.clone(),
          });
        }
      }
    });
    setBones(newBones);
  }, [item]);

  useFrame(() => {
    if (bones) {
      const worldRot = new Quaternion();
      item?.getWorldQuaternion(worldRot);
      const zRot = worldRot.z - prevRotation;
      if (zRot > 0.001 || zRot < -0.001) {
        setPrevRotation(worldRot.z);
        setNewRot(zRot);
      }

      const worldPos = new Vector3();
      item?.getWorldPosition(worldPos);
      const zPos = worldPos.x - prevPosition;
      if (zPos > 0.001 || zPos < -0.001) {
        setPrevPosition(worldPos.x);
        setNewPos(zPos);
      }

      bones.forEach((element) => {
        const endPos = newPos * element.depth * physicsStrength;
        const endRot = newRot * element.depth * physicsStrength;
        if (zAxis) {
          if (element.parentPos.z < 0) {
            element.object.position.setZ(
              element.initialPos.z + endPos + endRot
            );
          } else {
            element.object.position.setZ(
              element.initialPos.z - endPos - endRot
            );
          }
        }
      });
    }
  });

  return <></>;
}
