import { useEffect, useState } from "react";

import {
  setBooleanAction,
  setStringAction,
  RootState,
  setInfoAction,
} from "../store";
import { useDispatch, useSelector } from "react-redux";

import PositioningButton from "./ui/PositioningButton";
import Text from "./ui/Text";
import VisType from "./ui/VisType";

export default function UI() {
  const dispatch = useDispatch();

  const [droppingModel, setDroppingModel] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [visType, setVisType] = useState("prodvis");

  const vertices = useSelector((state: RootState) => state.vertices);
  const materialName = useSelector((state: RootState) => state.materialName);
  const powerOfTwo = useSelector((state: RootState) => state.powerOfTwo);
  const withinSize = useSelector((state: RootState) => state.withinSize);
  const square = useSelector((state: RootState) => state.square);
  const centered = useSelector((state: RootState) => state.centered);
  const modelScale = useSelector((state: RootState) => state.modelScale);
  const fileSize = useSelector((state: RootState) => state.fileSize);

  useEffect(() => {
    window.addEventListener("dragover", function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = "copy";
      }
      if (!droppingModel) {
        setDroppingModel(true);
      }
    });

    window.addEventListener("drop", function (e) {
      e.stopPropagation();
      e.preventDefault();

      if (e.dataTransfer) {
        if (
          e.dataTransfer.files[0].name.includes(".glb") ||
          e.dataTransfer.files[0].name.includes(".gltf")
        ) {
          const reader = new FileReader();

          reader.onloadend = (e) => {
            const result = e.target?.result as string;
            dispatch(setStringAction("CURRENT_ITEM", result));
          };

          reader.readAsDataURL(e.dataTransfer.files[0]);

          const sizeMb = e.dataTransfer.files[0].size / 1000000;
          if (sizeMb <= 10) {
            dispatch(
              setInfoAction("FILE_SIZE", {
                correct: true,
                info: "Model is below 10MB",
                moreInfo: `Model file size is ${sizeMb.toFixed(1)}MB`,
              })
            );
          } else {
            dispatch(
              setInfoAction("FILE_SIZE", {
                correct: false,
                info: "Model is more than 10MB",
                moreInfo: `Model file size is ${sizeMb.toFixed(1)}MB`,
              })
            );
          }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setBooleanAction("EDIT_OPEN", modalOpen));
    // eslint-disable-next-line
  }, [modalOpen]);

  useEffect(() => {
    if (visType === "prodvis") {
      dispatch(setBooleanAction("EDIT_OPEN", false));
      setModalOpen(false);
    }
    // eslint-disable-next-line
  }, [visType]);

  return (
    <>
      <div className="ui top-right">
        <Text data={vertices} />
        <Text data={materialName} />
        <Text data={powerOfTwo} />
        <Text data={withinSize} />
        <Text data={square} />
        <Text data={centered} />
        <Text data={modelScale} />
        <Text data={fileSize} />
      </div>
      <div className="ui top-left">
        <VisType setVisType={setVisType} />
        {visType === "vto" && (
          <PositioningButton
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
          />
        )}
      </div>
    </>
  );
}
