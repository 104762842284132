import { Suspense, useEffect, useRef, useState } from "react";
import {
  extend,
  useThree,
  ReactThreeFiber,
  useFrame,
} from "@react-three/fiber";

import { Group, Box3, Object3D, PerspectiveCamera, Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";

import { useSelector, useDispatch } from "react-redux";
import { setVec3Action, RootState } from "../store";

import Head from "./Head";
import Item from "./Item";
import Lighting from "./Lighting";
import Neck from "./Neck";

extend({ OrbitControls, TransformControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
      transformControls: ReactThreeFiber.Object3DNode<
        TransformControls,
        typeof TransformControls
      >;
    }
  }
}

export default function Scene() {
  const dispatch = useDispatch();
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const controls = useRef<OrbitControls>(null);
  const transform = useRef<TransformControls>(null);
  const turntable = useRef<Group>(null);
  const head = useRef<Group>(null);

  const [lastTouch, setLastTouch] = useState(0);

  const currentItem = useSelector((state: RootState) => state.currentItem);
  const visType = useSelector((state: RootState) => state.visualisationType);
  const editOpen = useSelector((state: RootState) => state.editOpen);
  const position = useSelector((state: RootState) => state.position);
  const rotation = useSelector((state: RootState) => state.rotation);
  const scale = useSelector((state: RootState) => state.scale);
  const transformType = useSelector((state: RootState) => state.transformType);

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (controls.current) {
      controls.current.addEventListener("start", () => {
        const currentTime = new Date().getTime() / 1000;
        setLastTouch(currentTime);
      });
    }

    if (transform.current) {
      transform.current.addEventListener("dragging-changed", function (event) {
        if (controls.current) {
          controls.current.enabled = !event.value;
          if (turntable.current) {
            dispatch(
              setVec3Action("POSITION", [
                turntable.current.position.x,
                turntable.current.position.y,
                turntable.current.position.z,
              ])
            );
            dispatch(
              setVec3Action("ROTATION", [
                turntable.current.rotation.x,
                turntable.current.rotation.y,
                turntable.current.rotation.z,
              ])
            );
            dispatch(
              setVec3Action("SCALE", [
                turntable.current.scale.x,
                turntable.current.scale.y,
                turntable.current.scale.z,
              ])
            );
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  function resetCamera() {
    if (controls.current && turntable.current && turntable.current.children) {
      if (controls.current.object instanceof PerspectiveCamera) {
        if (!editOpen) {
          fitCameraToSelection(
            controls.current.object,
            controls.current,
            turntable.current.children
          );
        } else {
          if (head.current && head.current.children) {
            fitCameraToSelection(
              controls.current.object,
              controls.current,
              head.current.children
            );
          }
        }
      }
    }
  }

  useFrame(() => {
    if (controls.current && visType === "prodvis") {
      const currentTime = new Date().getTime() / 1000;
      if (lastTouch !== 0 && currentTime > lastTouch + 5) {
        if (turntable.current) {
          // turntable.current.rotation.y += 0.002;
        }
      }
      controls.current.update();
    }
  });

  function fitCameraToSelection(
    camera: PerspectiveCamera,
    controls: OrbitControls,
    selection: Object3D[],
    fitOffset = 1.2
  ) {
    const box = new Box3();

    for (const object of selection) box.expandByObject(object);

    const size = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);

    controls.maxDistance = distance * 2;
    controls.minDistance = distance / 2;
    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);

    controls.update();
  }

  useEffect(() => {
    if (transform.current) {
      switch (transformType) {
        case "position":
          transform.current.setMode("translate");
          break;
        case "rotation":
          transform.current.setMode("rotate");
          break;
        case "scale":
          transform.current.setMode("scale");
          break;
        default:
          break;
      }
    }
  }, [transformType]);

  return (
    <>
      {(visType === "prodvis" || editOpen) && (
        <orbitControls
          ref={controls}
          args={[camera, domElement]}
          autoRotate={false}
          autoRotateSpeed={-0.5}
          enableDamping={!editOpen}
        />
      )}
      {editOpen ||
        (urlParams.get("physicsDebug") && (
          <transformControls
            ref={transform}
            args={[camera, domElement]}
            onUpdate={(transform) => {
              if (turntable.current) {
                transform.attach(turntable.current);
              }
            }}
          />
        ))}
      <Lighting prodVis={visType === "prodvis" || editOpen} />
      <Suspense fallback={null}>
        {visType !== "prodvis" && (
          <group ref={head}>
            <Head hidden={!editOpen} />
            {!editOpen && <Neck />}
          </group>
        )}
        <group
          ref={turntable}
          position={
            visType === "prodvis"
              ? [0, 0, 0]
              : [position[0], position[1], position[2]]
          }
          rotation={
            visType === "prodvis"
              ? [0, 0, 0]
              : [rotation[0], rotation[1], rotation[2]]
          }
          scale={
            visType === "prodvis" ? [1, 1, 1] : [scale[0], scale[1], scale[2]]
          }
        >
          <Item url={currentItem} resetCamera={resetCamera} />
        </group>
      </Suspense>
    </>
  );
}
