import { useState, useEffect, useRef } from "react";
import { useLoader } from "@react-three/fiber";
import { Mesh, Object3D, Material } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

type HeadProps = {
  hidden: boolean;
};

export default function Head({ hidden }: HeadProps) {
  const mesh = useRef<Object3D>();
  const { scene } = useLoader(GLTFLoader, "./headmesh.glb");
  const [model, setModel] = useState<Object3D>();

  // Fixes a weird issue where models don't reload
  useEffect(() => {
    setModel(scene);
  }, [scene]);

  useEffect(() => {
    if (hidden) {
      scene.renderOrder = Number.MIN_SAFE_INTEGER;
      ((scene.children[0] as Mesh).material as Material).colorWrite = false;
    } else {
      scene.renderOrder = 0;
      ((scene.children[0] as Mesh).material as Material).colorWrite = true;
    }
  }, [hidden, scene]);

  return model ? <primitive ref={mesh} object={model} /> : <></>;
}
