import "./styles/App.css";

import Scene from "./components/Scene";
import UI from "./components/UI";
import CanvasWrapper from "./components/CanvasWrapper";

import { Provider } from "react-redux";
import { store } from "./index";

function App() {
  return (
    <div className="App">
      <CanvasWrapper>
        <Provider store={store}>
          <Scene />
        </Provider>
      </CanvasWrapper>
      <UI />
    </div>
  );
}

export default App;
