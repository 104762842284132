import { useState, useEffect, useRef } from "react";
import { Mesh, Box3, Vector3, Group } from "three";

import { setInfoAction } from "../store";
import { useDispatch } from "react-redux";
import Physics from "./Physics";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

type ItemProps = {
  url: string;
  resetCamera: () => void;
};

export default function Item({ url, resetCamera }: ItemProps) {
  const item = useRef<Group>(null);
  const [content, setContent] = useState<Group>();
  const dispatch = useDispatch();

  function clear() {
    if (content && item.current) {
      item.current.remove(content);
      item.current.clear();

      content.traverse((node: any) => {
        if (!node.isMesh) return;
        node.geometry.dispose();
      });
    }
  }

  useEffect(() => {
    if (!content) return;

    // Get Vertices
    let vertices = 0;
    let materialNames: string[] = [];
    let nonPo2Images: string[] = [];
    let notWithinSizeImages: string[] = [];
    let nonSquareImages: string[] = [];
    //let hasTextures = false;

    const bbox = new Box3().setFromObject(content);

    let size = new Vector3();
    bbox.getSize(size);

    let center = new Vector3();
    bbox.getCenter(center);

    let centered = false;
    if (
      center.x > -1 &&
      center.x < 1 &&
      center.y > -1 &&
      center.y < 1 &&
      center.z > -1 &&
      center.z < 1
    ) {
      centered = true;
    }

    content.traverse((object) => {
      if (object instanceof Mesh) {
        const meshVertices = object.geometry.attributes.position;
        vertices += meshVertices.count;

        if (object.material.map) {
          //hasTextures = true;
          if (
            object.material.map.image.height !== object.material.map.image.width
          ) {
            nonSquareImages.push(object.material.name);
          }
          let po2 =
            (Math.log(object.material.map.image.height) / Math.log(2)) % 1 ===
            0;
          if (!po2) {
            nonPo2Images.push(object.material.name);
          }
          if (
            !(
              object.material.map.image.height <= 2048 &&
              object.material.map.image.width <= 2048
            )
          ) {
            notWithinSizeImages.push(object.material.name);
          }
        }

        materialNames.push(object.material.name);
      }
    });

    let materialNameCorrect = true;
    let wrongMaterials = [];
    for (let i = 0; i < materialNames.length; i++) {
      if (!materialNames[i].includes("M_")) {
        materialNameCorrect = false;
        wrongMaterials.push(materialNames[i]);
      } else if (materialNames[i].split("_").length - 1 === 3) {
        materialNameCorrect = false;
        wrongMaterials.push(materialNames[i]);
      }
    }

    // Vertices
    const verticesInfo = `There are ${vertices} Vertices`;
    let verticesCorrect = false;
    let verticesMoreInfo = "This is under the 30,000 vertices recommendation";
    if (vertices < 30000) {
      verticesCorrect = true;
    } else if (vertices < 100000) {
      verticesMoreInfo =
        "This is over the 30,000 vertices recommendation, however will be accepted if it is a complex model";
    } else {
      verticesMoreInfo =
        "This is over both the 30,000 recommendation and the 100,000 limit";
    }

    dispatch(
      setInfoAction("VERTICES", {
        correct: verticesCorrect,
        info: verticesInfo,
        moreInfo: `${verticesMoreInfo}. Models can be a maximum of 100K vertices for complex models, however the Mesh should be optimized with a max 30K vertices for most objects.`,
      })
    );

    // Material Name
    let materialNameMoreInfo = "";
    if (materialNameCorrect) {
      materialNameMoreInfo = "All materials named correctly";
    } else {
      materialNameMoreInfo = `Materials: ${wrongMaterials.join(
        ", "
      )} are named incorrectly`;
    }

    dispatch(
      setInfoAction("MATERIAL_NAME", {
        correct: materialNameCorrect,
        info: materialNameCorrect
          ? "Materials named correctly"
          : "Materials not named correctly",
        moreInfo: `${materialNameMoreInfo}. Materials must follow this naming convention: M_{MaterialID}_{MaterialName} and Material ID must start at 0 and go up by 1 for each material added eg. 0,1,2,3
          `,
      })
    );

    // Power of 2
    dispatch(
      setInfoAction("POWER_OF_TWO", {
        correct: nonPo2Images.length === 0,
        info:
          nonPo2Images.length === 0
            ? "Textures are power of 2"
            : "Textures are not power of 2",
        moreInfo: `${
          nonPo2Images.length === 0
            ? ""
            : `Textures: ${nonPo2Images.join(", ")}  are not power of 2.`
        } Only power of 2 textures are allowed
            `,
      })
    );

    // Within Size
    dispatch(
      setInfoAction("WITHIN_SIZE", {
        correct: notWithinSizeImages.length === 0,
        info:
          notWithinSizeImages.length === 0
            ? "Textures are below 2048x2048"
            : "Textures are above 2048x2048",
        moreInfo: `${
          notWithinSizeImages.length === 0
            ? ""
            : `Textures: ${notWithinSizeImages.join(", ")} are not within size.`
        } Only textures below 2048x2048 resolution are allowed
                `,
      })
    );

    // Square
    dispatch(
      setInfoAction("SQUARE", {
        correct: nonSquareImages.length === 0,
        info:
          nonSquareImages.length === 0
            ? "Textures are square"
            : "Textures must be square",
        moreInfo: `${
          nonSquareImages.length === 0
            ? ""
            : `Textures: ${nonSquareImages.join(", ")} are not square.`
        } Textures must be square
                `,
      })
    );

    // Centered
    dispatch(
      setInfoAction("CENTERED", {
        correct: centered,
        info: centered ? "Model is centered" : "Model is not centered",
        moreInfo: `Pivot positioned at the origin (0,0,0)`,
      })
    );

    let modelScale = true;
    if (size.x > 5 || size.y > 5 || size.z > 5) {
      modelScale = false;
    }

    // Model Scale
    dispatch(
      setInfoAction("MODEL_SCALE", {
        correct: modelScale,
        info: `${Math.round(size.x * 100) / 100}m W x ${
          Math.round(size.y * 100) / 100
        }m H x ${Math.round(size.z * 100) / 100}m D`,
        moreInfo: `Model scale: real world, 1 unit = 1 meter, most models should be under 5m on all axis however will not be rejected if the object is bigger.`,
      })
    );
  }, [content, dispatch]);

  useEffect(() => {
    clear();

    const loader = new GLTFLoader();
    loader.load(url, (gltf) => {
      if (item.current) {
        const scene = gltf.scene || gltf.scenes[0];
        setContent(scene);
        item.current.add(scene);
      }
    });

    return () => {
      clear();
    };
    /* eslint-disable-next-line */
  }, [url]);

  return (
    <>
      <group ref={item} visible={true} />
      <Physics item={content} />
    </>
  );
}
