import { useEffect } from "react";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { PMREMGenerator, sRGBEncoding, Color } from "three";
import { useThree } from "@react-three/fiber";

type hdrProps = {
  prodVis: boolean;
};

export default function Lighting({ prodVis }: hdrProps) {
  const three = useThree();

  //   Load HDR
  useEffect(() => {
    const pmremGenerator = new PMREMGenerator(three.gl);
    pmremGenerator.compileEquirectangularShader();
    let envMap;

    three.gl.outputEncoding = sRGBEncoding;
    three.gl.physicallyCorrectLights = true;

    const loader = new RGBELoader().load(
      `https://assets.poplar.studio/assets/HDR/neutral_correction/neutral_correction.hdr`,
      (texture) => {
        envMap = pmremGenerator.fromEquirectangular(texture).texture;
        three.scene.environment = envMap;
        // if (prodVis) {
        //   three.scene.background = envMap;
        // }
        three.scene.background = new Color("#cbced4");
        texture.dispose();
        pmremGenerator.dispose();
        loader.dispose();
      }
    );
  }, [three.scene, three.gl, prodVis]);

  useEffect(() => {
    const camera: any = three.camera;
    if (camera.backgroundTexture) {
      camera.backgroundTexture.encoding = sRGBEncoding;
      three.scene.background = camera.backgroundTexture;
    }
  }, [three.camera, three.scene]);

  return null;
}
