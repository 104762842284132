import { useEffect, useRef } from "react";
import { Mesh, Object3D, Material } from "three";

export default function Neck() {
  const mesh = useRef<Object3D>();

  useEffect(() => {
    if (mesh.current) {
      mesh.current.renderOrder = Number.MIN_SAFE_INTEGER;
      ((mesh.current as Mesh).material as Material).colorWrite = false;
    }
  }, []);

  return (
    <mesh position={[0, -3, 0]} ref={mesh}>
      <cylinderGeometry args={[0.5, 0.5, 6]} />
      <meshStandardMaterial />
    </mesh>
  );
}
