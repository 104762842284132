import { Dispatch, SetStateAction } from "react";

import { useDispatch } from "react-redux";
import { setStringAction } from "../../store";

type VisTypeProps = {
  setVisType: Dispatch<SetStateAction<string>>;
};

export default function VisType({ setVisType }: VisTypeProps) {
  const dispatch = useDispatch();

  return (
    <div className="ui-boolean">
      <p>Visualisation Type:</p>
      <select
        name="type"
        id="type"
        onChange={(e) => {
          dispatch(setStringAction("VISUALISATION_TYPE", e.target.value));
          setVisType(e.target.value);
        }}
      >
        <option value="prodvis">Product Vis</option>
        <option value="vto">Try On</option>
      </select>
    </div>
  );
}
