import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { setStringAction } from "../../store";

type buttonProps = {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalOpen: boolean;
};

export default function PositioningButton(props: buttonProps) {
  const dispatch = useDispatch();

  return (
    <>
      <button
        className="ui-button"
        onClick={() => {
          props.setModalOpen(!props.modalOpen);
        }}
      >
        <p>
          {props.modalOpen
            ? "Close Position/Rotation/Scale Tool"
            : "Change Position/Rotation/Scale"}
        </p>
      </button>
      {props.modalOpen && (
        <div className="ui-bottom-left">
          <button
            className="ui-button"
            onClick={() => {
              dispatch(setStringAction("TRANSFORM_TYPE", "position"));
            }}
          >
            <p>Set Position</p>
          </button>
          <button
            className="ui-button"
            onClick={() => {
              dispatch(setStringAction("TRANSFORM_TYPE", "rotation"));
            }}
          >
            <p>Set Rotation</p>
          </button>
          <button
            className="ui-button"
            onClick={() => {
              dispatch(setStringAction("TRANSFORM_TYPE", "scale"));
            }}
          >
            <p>Set Scale</p>
          </button>
        </div>
      )}
    </>
  );
}
