import { useState } from "react";

type textProps = {
  data: {
    correct: boolean;
    info: string;
    moreInfo: string;
  };
};

export default function Text({ data }: textProps) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="ui-text">
      <p style={data.correct ? { color: "#006600" } : { color: "#990000" }}>
        {data.info}
      </p>
      <button
        className="more-info-button"
        onMouseOver={() => {
          setShowInfo(true);
        }}
        onMouseLeave={() => {
          setShowInfo(false);
        }}
      >
        ?
        {showInfo && (
          <div className="more-info">
            <p style={{ textAlign: "left" }}>{data.moreInfo}</p>
          </div>
        )}
      </button>
    </div>
  );
}
