import { combineReducers } from "redux";
import { Types } from "@zappar/zappar-react-three-fiber";

type stringActionType = {
  type: string;
  payload: string;
};

type infoActionType = {
  type: string;
  payload: {
    correct: boolean;
    info: string;
    moreInfo: string;
  };
};

type trackerActionType = {
  type: string;
  payload: Types.FaceAnchorGroup;
};

type booleanActionType = {
  type: string;
  payload: boolean;
};

type vec3ActionType = {
  type: string;
  payload: [number, number, number];
};

const currentItem = (
  state = urlParams.get("physicsDebug")
    ? "https://staging.media.poplar.studio/storage/ps-ew2-staging-models/469/glb/deep_wave_12_with_bonesalpha_blend.glb"
    : "https://media.poplar.studio/storage/ps-ew2-production-models/950570/glb/dealwithitnew.glb",
  action: stringActionType
) => {
  switch (action.type) {
    case "CURRENT_ITEM":
      return action.payload;
    default:
      return state;
  }
};

const urlParams = new URLSearchParams(window.location.search);
const visualisationType = (
  state = urlParams.get("physicsDebug") ? "vto" : "prodvis",
  action: stringActionType
) => {
  switch (action.type) {
    case "VISUALISATION_TYPE":
      return action.payload;
    default:
      return state;
  }
};

const editOpen = (state = false, action: booleanActionType) => {
  switch (action.type) {
    case "EDIT_OPEN":
      return action.payload;
    default:
      return state;
  }
};

const transformType = (state = "position", action: stringActionType) => {
  switch (action.type) {
    case "TRANSFORM_TYPE":
      return action.payload;
    default:
      return state;
  }
};

const tracker = (state = "", action: trackerActionType) => {
  switch (action.type) {
    case "TRACKER":
      return action.payload;
    default:
      return state;
  }
};

const position = (state = [0, 0, 0], action: vec3ActionType) => {
  switch (action.type) {
    case "POSITION":
      return action.payload;
    default:
      return state;
  }
};

const rotation = (state = [0, 0, 0], action: stringActionType) => {
  switch (action.type) {
    case "ROTATION":
      return action.payload;
    default:
      return state;
  }
};

const scale = (state = [1, 1, 1], action: vec3ActionType) => {
  switch (action.type) {
    case "SCALE":
      return action.payload;
    default:
      return state;
  }
};

const vertices = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "VERTICES":
      return action.payload;
    default:
      return state;
  }
};

const materialName = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "MATERIAL_NAME":
      return action.payload;
    default:
      return state;
  }
};

const powerOfTwo = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "POWER_OF_TWO":
      return action.payload;
    default:
      return state;
  }
};

const withinSize = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "WITHIN_SIZE":
      return action.payload;
    default:
      return state;
  }
};

const square = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "SQUARE":
      return action.payload;
    default:
      return state;
  }
};

const centered = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "CENTERED":
      return action.payload;
    default:
      return state;
  }
};

const modelScale = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "MODEL_SCALE":
      return action.payload;
    default:
      return state;
  }
};

const fileSize = (state = "", action: infoActionType) => {
  switch (action.type) {
    case "FILE_SIZE":
      return action.payload;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  currentItem,
  visualisationType,
  editOpen,
  position,
  rotation,
  scale,
  transformType,
  vertices,
  materialName,
  powerOfTwo,
  withinSize,
  square,
  centered,
  modelScale,
  tracker,
  fileSize,
});

export const setStringAction = (type: string, newValue: string) => {
  return {
    type: type,
    payload: newValue,
  };
};

export const setBooleanAction = (type: string, newValue: boolean) => {
  return {
    type: type,
    payload: newValue,
  };
};

export const setVec3Action = (
  type: string,
  newValue: [number, number, number]
) => {
  return {
    type: type,
    payload: newValue,
  };
};

export const setTrackerAction = (
  type: string,
  newValue: Types.FaceAnchorGroup
) => {
  return {
    type: type,
    payload: newValue,
  };
};

export const setInfoAction = (
  type: string,
  newValue: {
    correct: boolean;
    info: string;
    moreInfo: string;
  }
) => {
  return {
    type: type,
    payload: newValue,
  };
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
